<template>
    <div class="w-full h-full fixed pt-[10px] bottom-0 z-[999] bg-[#FFF]">
        <div @click="$emit('close')" class="flex text-[#4A4E69] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#4A4E69] pb-2 text-gray-emb text-[20px] font-bold text-center w-[90%] mx-auto">
            {{ training.date.split('T')[0].split('-').reverse().join('.') }}
        </div>

        <div class="flex flex-col items-center mt-[100px]">
            <div class="text-[20px] font-bold text-red-atten">
                група заповнена
            </div>
            <div class="text-[20px] leading-[20px] text-center w-[80%] mx-auto font-bold text-red-atten mt-[22px]">
                можливість запису на це тренування - відсутня
            </div>
            <div class="mt-[22px]">
                <div 
                    class="h-[22px] w-[22px] border-[2px] border-red-atten ml-[5px] rounded-full bg-red-atten"
                ></div>
            </div>
        </div>

        <div class="flex flex-col items-center mt-[100px]">
            <div class="text-[20px] text-center font-bold text-green-emb">
                оберіть зручний час із<br>доступних варіантів
            </div>
            <div class="mt-[22px]">
                <div 
                    class="h-[22px] w-[22px] border-[2px] border-green-emb ml-[5px] rounded-full bg-green-emb"
                ></div>
            </div>
        </div>



        <SquircleComponent
            :smooth="60" 
            :radius="22" 
            :width="354" 
            :height="44" 
            :outlineWidth="1"
            @click="$emit('close')"
            class="bg-green-emb left-[50%] translate-x-[-50%] disabled:opacity-40 mx-auto absolute bottom-[30px] w-[363px] border-[1px] items-center px-[13px] flex h-[44px] justify-center mb-[16px]"
        >   
            <div :disabled="timeToStart <= 0" class="w-full text-[13px] text-gray-emb font-extrabold text-center">Перейти до вибору тренування</div>
        </SquircleComponent>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useMainStore } from '@/mobileApp/store/mainStore';
    import { deleteClientTrainingById } from '@/mobileApp/services/apiRequests';
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue';

    export default {
        name: 'GroupFullfieldView',

        props: {
            training: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL);
            const store = useMainStore();
            const router = useRouter();

            const timerText = ref('');

            onMounted(() => {

                timeToStart.value = new Date(props.training.date.split('T')[0] + 'T' + props.training.start_time + ':00').getTime() - new Date().getTime();
                console.log('startTrainingDate.value', timeToStart.value);
                console.log('props.training.date', props.training.date);
                console.log('timeToStart.value', timeToStart.value);


            });

            const secondsToTime = () => {
                console.log(timeToStart.value);
                const daysToStart = Math.floor(timeToStart.value / (1000 * 60 * 60 * 24));
                const weeksToStart = Math.floor(daysToStart / 7);
                const hoursToStart = Math.floor((timeToStart.value / (1000 * 60 * 60)) % 24) < 10 ? '0' + Math.floor((timeToStart.value / (1000 * 60 * 60)) % 24) : Math.floor((timeToStart.value / (1000 * 60 * 60)) % 24);
                const minutesToStart = Math.floor((timeToStart.value / 1000 / 60) % 60) < 10 ? '0' + Math.floor((timeToStart.value / 1000 / 60) % 60) : Math.floor((timeToStart.value / 1000 / 60) % 60);
                const secToStart = Math.floor((timeToStart.value / 1000) % 60) < 10 ? '0' + Math.floor((timeToStart.value / 1000) % 60) : Math.floor((timeToStart.value / 1000) % 60);
                
                return `${weeksToStart > 0 ? weeksToStart + 'тж. ' : ''}${daysToStart > 0 ? daysToStart + 'д. ' : ''}${hoursToStart}:${minutesToStart}:${secToStart}`;
            }

            const timeToStart = ref(0);

            const startTrainingDate = ref(new Date(props.training.date + 'T' + props.training.start_time));

            const deleteBook = async () => {
                const response = await deleteClientTrainingById(props.training.training_id, store.student.student_id);
                router.go();
            }

            const parseType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчинг'
                    case 'pilates_preg':
                        return 'пілатес(вагітні)'
                }
            }

            return {
                apiURL,
                deleteBook,
                parseType,
                timeToStart,
                secondsToTime,
                timerText
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>

</style>