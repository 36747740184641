<template>
    <div class="absolute bg-[#FFF] w-full pb-6">
        <div @click="$router.go(-1)" class="flex text-[#A1D9F7] items-center ml-3 mt-4 mb-5">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>
        <div class="text-xs text-[#4A4E69] w-[90%] mx-auto mt-7">
            <div style="width: 100%; height: 2449px">
                <span style="color: #4A4E69; font-size: 10px; font-weight: 300; word-wrap: break-word">
                    Запис на тренування можливий за номером тел. +380 66 471 81 80, через сторінку Інстаграм @embody.ua та через додаток в робочий час з понеділка по пʼятницю з 08:00 до 20:00, та в суботу з 09:00 до 13:00. Цілодобово та в неробочий час керування записами на тренування можливе через додаток. 

Абонементи на тренування доступні на 1, 4, 8 та 10 разів. Термін дії - 1 місяць. 

Види абонементів поділяються на групові (до 6 осіб), спліт (до 3 осіб), та індивідуальні (1 особа). Купуючи абонемент конкретного виду, клієнт має доступ відвідувати всі заняття доступні в графіку по даному виду занять. 

Абонемент надає можливість відвідувати 5 напрямків занять: барре, йога, стретчинг, пілатес на матах та пілатес на реформерах.  

Актуальний графік доступний в додатку та на сторінці Інстаграму. Радимо створювати записи наперед задля забезпечення вільного місця на бажане тренування.

Відміна тренування можлива за 6 годин до початку групового заняття, та за 12 год. до початку спліт/індивідуального заняття.

Можливі форми оплати послуг: готівкова та безготівкова. 

Тренування в нашій студії дозволені лише для повнолітніх. Вікові обмеження вводяться з точки зору безпеки, як для клієнта, так і для тренера. Специфіка занять побудована для повнолітніх і вікові обмеження за дозволом батьків зміні не підлягають. 

Пілатес для вагітних проводиться враховуючи наступні умови: 2-3 триместр, довідка-дозвіл на заняття пілатесом, заповнення анкети перед тренуванням.

З метою дотримання техніки безпеки, ми не допускаємо малолітніх осіб в зал для
тренувань. Персонал нашого простору
не несе відповідальність за дітей, які залишаються очікувати на Вас на рецепції.

Ми обожнюємо пухнастиків, однак наша студія - не місце для тварин.


EMBODY не є медичним/ реабілітаційним центром та наші тренери не є лікарями. Ми радо допоможемо вам віднайти баланс із вашим тілом, проте просимо вас памʼятати, що EMBODY, в першу чергу, — фітнес студія.

З приводу будь-яких запитань Ви можете звʼязатися з нами через сторінку  Інстаграм (@embody.ua) або телефон (+380 66 4718180) в робочий час (Пн-Пт 08:00-20:00, Сб 09:00-13:00). Керувати записами на тренування цілодобово можливо через додаток. 

З повагою, 
Адміністрація студії Embody
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRouter, useRoute } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    export default {
        name: 'SchoolRules',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const store = useMainStore()

            const updatePersonalDataState = async () => {
                await store.updateSchoolRulesAgreement()
                router.push('/')
            }

            return {
                router,
                route,
                store,
                updatePersonalDataState
            }
        }
    }
</script>