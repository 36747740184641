<template>
    <div class="w-full h-full fixed pt-[10px] bottom-0 z-[999] bg-[#FFF]">
        <div @click="$emit('close')" class="flex text-[#4A4E69] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#4A4E69] pb-2 text-gray-emb text-[20px] font-bold text-center w-[90%] mx-auto">
            {{ training.date.split('T')[0].split('-').reverse().join('.') }}
        </div>

        <div class="w-full flex justify-center mt-[20px]">
            <div class="">

            </div>
        </div>
        <div class="w-full flex justify-center mt-[100px]">
            <div class="text-[#6BA292]" style="font-size: 20px; font-weight: 800; line-height: 20px; letter-spacing: 0em; text-align: center;">
                чекаємо вас на тренуванні: 
            </div>
        </div>

        <SquircleComponent
            class="mt-[34px] relative bg-[#DBEBEA] mx-auto"
            :smooth="70" 
            :radius="44" 
            :width="354" 
            :height="78" 
            :outlineWidth="0"
        >
                <div class="flex text-[#4A4E69] relative top-[15px] items-center ml-[10px] mr-[16px] ">
                    <div class="w-full pt-[3px] flex items-center">
                        <div class="w-[52.1px] h-[44px] mr-[10px] relative bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                            <img v-if="training?.trainer_photo" class="w-full h-full bg-white " :src="apiURL + 'static/' + training?.trainer_photo">
                            <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training.trainer_label.split(' ')[0][0].toUpperCase() + training.trainer_label.split(' ')[1][0].toUpperCase() }}</div>
                        </div>
                        <div class="w-full mt-[px]">
                            <div class="w-full border-opacity-20 pb-[10px]">
                                <div class="flex w-full">
                                    <div class="font-bold relative text-[16px] text-gray-emb bottom-[5px] w-[37%] truncate">{{ training.trainer_label.split(' ')[0] + ' ' + training.trainer_label.split(' ')[1][0] + '.'  }}</div>
                                    <div class="font-bold text-[13px] relative text-gray-emb bottom-[1px]">{{ training.tariff_group_name }}</div>
                                </div>
                                <div class="flex justify-start w-full">
                                    <div class="flex w-[37%] relative top-[6px]">
                                        <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.start_time }}</div>
                                        <div class="mx-[3px] text-[11px] text-gray-emb">
                                            <svg class="scale-50 origin-center relative top-[2px]" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 11L5.29289 6.70711C5.62623 6.37377 5.79289 6.20711 5.79289 6C5.79289 5.79289 5.62623 5.62623 5.29289 5.29289L1 1" stroke="#525A60" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.end_time }}</div>
                                    </div>
                                    <div class="text-start text-[11px] text-gray-emb relative top-[6px] font-bold">{{ parseType(training.type) }}</div>
                                    <!-- <div class="truncate text-[#6BA292] font-semibold w-[120px]">{{ 0 }}/{{ training.max_clients }}</div> -->
                                    <div class="flex ml-auto mt-[-13px]">
                                        <div class="text-[11px] w-[55px] mt-[4px] text-gray-emb">
                                            {{training.location_label}}
                                        </div>
                                        <div 
                                            class="truncate text-[#6BA292] font-bold w-[30px] text-start"
                                            :class="{
                                                'text-[#6BA292]' : training.clientsCount < training.max_clients,
                                                'text-[#BF637D]' : training.clientsCount >= training.max_clients
                                            }"    
                                        >{{ training.clientsCount }}/{{ training.max_clients }}</div>
                                        <div 
                                            class="h-[22px] w-[22px] border-[2px] ml-[5px] rounded-full"
                                            :class="{
                                                'bg-[#6BA292] border-[#6BA292]' : training.clientsCount < training.max_clients,
                                                'bg-[#BF637D] border-[#BF637D]' : training.clientsCount >= training.max_clients
                                            }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </SquircleComponent>

        <div class="w-full flex flex-col items-center justify-center mt-[100px]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="#990D35" stroke-width="1.5"/>
            <path d="M11.991 15H12" stroke="#990D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 12L12 8" stroke="#990D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div v-if="timeToStart >= 0" class="text-[#BF637D] text-center px-[30px] mt-[34px] text-[13px]">
                якщо бажаєте скасувати запис<br>на тренування - натисніть кнопку<br>ВІДМІНИТИ
            </div>
            <div v-if="timeToStart <= 0" class="text-[#BF637D] text-center px-[30px] mt-[34px] text-[13px]">
                Час для можливості відмінити<br>запис - вичерпано!
            </div>
            <div v-if="timeToStart > 0" class="mt-[100px] text-[#BF637D] flex justify-center flex-col items-center">
                <div>термін скасування:</div>
                <div style="font-size: 20px; font-weight: 800; line-height: 20px; letter-spacing: 0em; text-align: center;">{{ timerText }}</div>
            </div>
        </div>

        <!-- <SquircleComponent
            class="mt-[34px] relative bg-[#DBEBEA] mx-auto"
            :smooth="70" 
            :radius="44" 
            :width="354" 
            :height="44" 
            :outlineWidth="0"
        >
        <button @click="deleteBook" :disabled="timeToStart <= 0" class="bg-red-atten disabled:opacity-40 mx-auto absolute bottom-[30px] left-[50%] translate-x-[-50%] w-[363px] border-[1px] border-red-atten rounded-[9px] items-center px-[13px] flex h-[44px] justify-center mb-[16px]">
            <div class="w-full text-[16px] text-white font-extrabold">Відмінити</div>

        </button>
        </SquircleComponent> -->

        <SquircleComponent
            :smooth="60" 
            :radius="22" 
            :width="354" 
            :height="44" 
            :outlineWidth="1"
            @click="deleteBook"
            v-if="timeToStart >= 0"
            class="bg-red-atten left-[50%] translate-x-[-50%] disabled:opacity-40 mx-auto absolute bottom-[30px] w-[363px] border-[1px] border-red-atten items-center px-[13px] flex h-[44px] justify-center mb-[16px]"
        >   
            <button class="w-full text-white font-extrabold text-center">Відмінити</button>
        </SquircleComponent>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useMainStore } from '@/mobileApp/store/mainStore';
    import { deleteClientTrainingById } from '@/mobileApp/services/apiRequests';
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue';

    export default {
        name: 'BookedTrainingView',

        props: {
            training: {
                type: Object,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL);
            const store = useMainStore();
            const router = useRouter();

            const timerText = ref('');

            onMounted(() => {
                if (props.training.tariff_group_name != 'TEAM') {
                    timeToStart.value = new Date(props.training.date.split('T')[0] + 'T' + props.training.start_time + ':00').getTime() - new Date().getTime() - (12 * 60 * 60 * 1000);
                } else {
                    timeToStart.value = new Date(props.training.date.split('T')[0] + 'T' + props.training.start_time + ':00').getTime() - new Date().getTime() - (6 * 60 * 60 * 1000);
                }
                
                
                console.log('startTrainingDate.value', timeToStart.value);
                console.log('props.training.date', props.training.date);
                console.log('timeToStart.value', timeToStart.value);

                window.setInterval(() => {
                    timeToStart.value -= 1000;
                    timerText.value = secondsToTime();
                }, 1000);

            });

            const secondsToTime = () => {
                console.log(timeToStart.value);
                const daysToStart = Math.floor(timeToStart.value / (1000 * 60 * 60 * 24));
                const weeksToStart = Math.floor(daysToStart / 7);
                const hoursToStart = Math.floor((timeToStart.value / (1000 * 60 * 60)) % 24) < 10 ? '0' + Math.floor((timeToStart.value / (1000 * 60 * 60)) % 24) : Math.floor((timeToStart.value / (1000 * 60 * 60)) % 24);
                const minutesToStart = Math.floor((timeToStart.value / 1000 / 60) % 60) < 10 ? '0' + Math.floor((timeToStart.value / 1000 / 60) % 60) : Math.floor((timeToStart.value / 1000 / 60) % 60);
                const secToStart = Math.floor((timeToStart.value / 1000) % 60) < 10 ? '0' + Math.floor((timeToStart.value / 1000) % 60) : Math.floor((timeToStart.value / 1000) % 60);
                
                return `${weeksToStart > 0 ? weeksToStart + 'тж. ' : ''}${daysToStart > 0 ? daysToStart + 'д. ' : ''}${hoursToStart}:${minutesToStart}:${secToStart}`;
            }

            const timeToStart = ref(0);

            const startTrainingDate = ref(new Date(props.training.date + 'T' + props.training.start_time));

            const deleteBook = async () => {
                const response = await deleteClientTrainingById(props.training.training_id, store.student.student_id);
                router.go();
            }

            const parseType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчинг'
                    case 'pilates_preg':
                        return 'пілатес(вагітні)'
                }
            }

            return {
                apiURL,
                deleteBook,
                parseType,
                timeToStart,
                secondsToTime,
                timerText
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>

</style>