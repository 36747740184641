<template>
    <div>
        <div @click="$router.push('/')" class="flex text-gray-emb items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-gray-emb">
                Назад
            </div>
        </div>

        <div class="text-gray-emb mt-[20px] flex justify-between mb-[0px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[13px] font-bold">РЕЄСТРАЦІЯ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>


        <form v-if="!registeredSuccess" action="" @submit.prevent="registerNewUser" class="px-[20px] mt-[30px] flex flex-col items-center gap-[20px]">
            <div class="border-gray-emb border-[2px] rounded-md px-[20px] py-[5px] w-full">
                <input v-model="surname" required type="text" placeholder="Прізвище" class="w-full outline-none">
            </div>
            <div class="border-gray-emb border-[2px] rounded-md px-[20px] py-[5px] w-full">
                <input v-model="name" required type="text" placeholder="Ім'я" class="w-full outline-none">
            </div>
            <div class="border-gray-emb border-[2px] rounded-md px-[20px] py-[5px] w-full"> 
                <input v-model="birthDate" required type="date" placeholder="Ім'я" class="w-full outline-none">
            </div>
            <div class="border-gray-emb border-[2px] flex rounded-md px-[20px] py-[5px] w-full">
                <div>
                    +380
                </div>
                <input 
                    v-model="phone" 
                    required 
                    type="text" 
                    placeholder="000000000" 
                    class="w-full ml-[10px] outline-none"
                    minlength="9"    
                >
            </div>
            <div v-if="checkPhoneFlag" class="text-red-500 text-center">
                Номер телефона вже зареєстровано
            </div>

            <div class="flex justify-between">
                <input v-model="confirmAgreement" required type="checkbox" id="agree" name="agree" value="agree">
                <label for="agree" class="ml-[10px] text-[12px] text-gray-emb">Я погоджуюсь з <span class="text-black underline" @click="$router.push('/school-rules')">умовами користування</span></label>
            </div>

            <div>
                <VueClientRecaptcha
                    :key="refreshCaptcha"
                    class="flex items-center justify-center"
                    @getCode="getCaptchaCode"
                    :value="captchaCode"
                />
            </div>
            <div class="border-gray-emb border-[2px] rounded-md px-[20px] py-[5px] w-full">
                <input type="text" required v-model="captchaAnswer" placeholder="Капча" class="w-full outline-none">
            </div>
            <div v-if="checkValidCaptchaFlag" class="text-red-500 text-center">
                Капча заповнена невірно! Зауважте, регістр символів має значення.
            </div>

            <div class="flex justify-center w-[100%] mt-[] mb-[33px]">
                <button type="submit">
                    <SquircleComponent 
                        class="bg-gray-emb w-[363px] border-[1px] border-rose-emb items-center px-[13px] flex justify-center mb-[16px]"
                        :smooth="60" 
                        :radius="22" 
                        :width="363" 
                        :height="44" 
                        :outlineWidth="1"    
                    >
                        <div class="w-full text-[20px] text-white font-extrabold text-center">Реєстрація</div>
                    </SquircleComponent>
                </button>
            </div>
        </form>
        
        <div v-if="registeredSuccess">
            <div class="text-gray-emb mt-[30px] text-center px-[20px]">
                Ви успішно зареєстровані! За 10 хв вам прийде смс з паролем для входу в додаток
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue'
import VueClientRecaptcha from 'vue-client-recaptcha'
// import 'vue-client-recaptcha/dist/style.css';
import { registerStudent, isPhoneNumberExists } from '@/mobileApp/services/apiRequests'

export default {
    name: 'RegistrationView',

    setup() {
        const captchaCode = ref('')
        const captchaAnswer = ref('')
        const checkValidCaptchaFlag = ref(false)
        const checkPhoneFlag = ref(false)
        const refreshCaptcha = ref(1)

        const surname = ref('')
        const name = ref('')
        const birthDate = ref('')
        const phone = ref('')
        const confirmAgreement = ref(false)

        const registeredSuccess = ref(false)

        const getCaptchaCode = (code) => {
            captchaCode.value = code
        }

        const registerNewUser = async () => {
            checkValidCaptchaFlag.value = !(captchaCode.value === captchaAnswer.value)

            if (!checkValidCaptchaFlag.value) {
                const isPhoneExists = (await isPhoneNumberExists('380' + phone.value)).data
                console.log(isPhoneExists)

                if (isPhoneExists) {
                    console.log('phone number is already exists')
                    checkPhoneFlag.value = true
                } else {
                    checkPhoneFlag.value = false
                    const response = await registerStudent({
                        surname: surname.value,
                        name: name.value,
                        birthday: new Date(birthDate.value).toISOString(),
                        phone: '380' + phone.value
                    })

                    debugger

                    if (response.status === 200) {
                        console.log('user is registered')
                        registeredSuccess.value = true
                    } else {
                        console.log('user is not registered')
                    }
                }
            } else {
                console.log('captcha is not valid')
                refreshCaptcha.value += 1
            }
        }

        return {
            captchaCode, 
            captchaAnswer, 
            getCaptchaCode,
            surname,
            name,
            birthDate,
            phone,
            confirmAgreement,
            checkValidCaptchaFlag,
            registerNewUser,
            registeredSuccess,
            checkPhoneFlag,
            refreshCaptcha
        }
    },

    components: {
        SquircleComponent, VueClientRecaptcha
    }
}
</script>
<style lang="scss">
    
</style>