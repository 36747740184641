<template>
    <div v-if="!loading" class="w-full h-full fixed pt-[10px] bottom-0 z-[999] bg-[#FFF]">
        <div @click="$emit('close')" class="flex text-[#4A4E69] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#4A4E69] pb-2 text-gray-emb text-[20px] font-bold text-center w-[90%] mx-auto">
            {{ training.date.split('T')[0].split('-').reverse().join('.') }}
        </div>

        <div class="w-full flex justify-center mt-[20px]">
            <div class="">

            </div>
        </div>
        <div class="w-full flex justify-center mt-[100px]">
            <div class="text-[#6BA292]" style="font-size: 20px; font-weight: 800; line-height: 20px; letter-spacing: 0em; text-align: center;">
                ви обрали запис на<br>тренування: 
            </div>
        </div>




        <SquircleComponent
            class="mt-[34px] relative bg-[#DBEBEA] mx-auto"
            :smooth="70" 
            :radius="44" 
            :width="354" 
            :height="78" 
            :outlineWidth="0"
        >
                <div class="flex text-[#4A4E69] relative top-[15px] items-center ml-[10px] mr-[16px] ">
                    <div class="w-full pt-[3px] flex items-center">
                        <div class="w-[52.1px] h-[44px] mr-[10px] relative bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                            <img v-if="training?.trainer_photo" class="w-full h-full bg-white " :src="apiURL + 'static/' + training?.trainer_photo">
                            <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training.trainer_label.split(' ')[0][0].toUpperCase() + training.trainer_label.split(' ')[1][0].toUpperCase() }}</div>
                        </div>
                        <div class="w-full mt-[px]">
                            <div class="w-full border-opacity-20 pb-[10px]">
                                <div class="flex w-full">
                                    <div class="font-bold relative text-[16px] text-gray-emb bottom-[5px] w-[37%]">{{ training.trainer_label.split(' ')[0]  }}</div>
                                    <div class="font-bold text-[13px] relative text-gray-emb bottom-[1px]">{{ training.tariff_group_name }}</div>
                                </div>
                                <div class="flex justify-start w-full">
                                    <div class="flex w-[37%] relative top-[6px]">
                                        <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.start_time }}</div>
                                        <div class="mx-[3px] text-[11px] text-gray-emb">
                                            <svg class="scale-50 origin-center relative top-[2px]" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 11L5.29289 6.70711C5.62623 6.37377 5.79289 6.20711 5.79289 6C5.79289 5.79289 5.62623 5.62623 5.29289 5.29289L1 1" stroke="#525A60" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.end_time }}</div>
                                    </div>
                                    <div class="text-start text-[11px] text-gray-emb relative top-[6px] font-bold">{{ parseType(training.type) }}</div>
                                    <!-- <div class="truncate text-[#6BA292] font-semibold w-[120px]">{{ 0 }}/{{ training.max_clients }}</div> -->
                                    <div class="flex ml-auto mt-[-13px]">
                                        <div class="text-[11px] w-[55px] mt-[4px] text-gray-emb">
                                            {{training.location_label}}
                                        </div>
                                        <div 
                                            class="truncate text-[#6BA292] font-bold w-[30px] text-start"
                                            :class="{
                                                'text-[#6BA292]' : training.clientsCount < training.max_clients,
                                                'text-[#BF637D]' : training.clientsCount >= training.max_clients
                                            }"    
                                        >{{ training.clientsCount }}/{{ training.max_clients }}</div>
                                        <div 
                                            class="h-[22px] w-[22px] border-[2px] ml-[5px] rounded-full"
                                            :class="{
                                                'bg-[#6BA292] border-[#6BA292]' : training.clientsCount < training.max_clients,
                                                'bg-[#BF637D] border-[#BF637D]' : training.clientsCount >= training.max_clients
                                            }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        <!-- <SquircleComponent
                :smooth="70" 
                :radius="40" 
                :width="350" 
                :height="75" 
                :outlineWidth="1"
                class="bg-[#E7F1F1] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            >

            </SquircleComponent> -->
        </SquircleComponent>

        <div v-if="clientAvalaibelBooks > 0 && currentTarrifDateExpire >= trainingDate" class="w-full flex flex-col items-center justify-center mt-[200px]">
            <div class="text-[#BF637D] text-center px-[30px] text-[13px]">
                у вас буде можливість скасувати запис не пізніше, ніж за <span v-if="training.tariff_group_name !='TEAM'">12</span><span v-else>6</span> годин до початку цього тренування
            </div>
        </div>

        <div v-if="clientAvalaibelBooks <= 0 && currentTarrifDateExpire >= trainingDate" class="w-full flex flex-col items-center justify-center mt-[200px]">
            <div class="text-[#BF637D] text-center px-[30px] text-[13px]">
                у вас не залишилось вільних бронювань за поточним абонементом
            </div>
        </div>

        <div v-if="currentTarrifDateExpire <= trainingDate && clientAvalaibelBooks > 0" class="w-full flex flex-col items-center justify-center mt-[200px]">
            <div class="text-[#BF637D] text-center px-[30px] text-[13px]">
                термін дії вашого абонементу не покриває це тренування
            </div>
        </div>

        <button  @click="addClientBook" :disabled="clientAvalaibelBooks <= 0 || currentTarrifDateExpire <= trainingDate" class="bg-green-emb disabled:opacity-50 mx-auto absolute bottom-[30px] left-[50%] translate-x-[-50%] w-[363px] rounded-[9px] items-center px-[13px] flex h-[44px] justify-center mb-[16px]">
            <div class="w-full text-[13px] text-gray-emb font-extrabold">Підтвердити</div>

        </button>
    </div>
    <div v-else class="w-full h-full fixed pt-[10px] bottom-0 z-[999] bg-[#FFF]">
        <div class="w-full h-full flex items-center justify-center">
            <div class="w-[50px] h-[50px] border-[5px] border-[#BF637D] rounded-full border-t-transparent animate-spin"></div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useMainStore } from '@/mobileApp/store/mainStore';
    import { deleteClientTrainingById, addClientTrainingBook, getClientsTrainingByClientId, getAllGroupAndItsTariffs, getAllClientTariffsByClientId } from '@/mobileApp/services/apiRequests';
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue';

    export default {
        name: 'PlanedTrainingView',

        props: {
            training: {
                type: Object,
                default: () => {}
            },
            currentTarriffId : {
                type: Number,
                default: () => {}
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL);
            const store = useMainStore();
            const router = useRouter();

            const clientBooks = ref([]);
            const allTarriffsGroups = ref([]);
            const currentTarriffGroup = ref(null);
            const currentGroupTarriffs = ref([]);
            const clientAvalaibelBooks = ref(0);
            const currentClientsTariffs = ref([]);
            
            const currentTarriff = ref(null);
            const currentTarrifDateExpire = ref(null);

            const trainingDate = ref(new Date(props.training.date).getTime());

            const loading = ref(false);

            onMounted(async () => {
                allTarriffsGroups.value = (await getAllGroupAndItsTariffs()).data
                console.log('allTarriffsGroups.value', props.training)
                clientBooks.value = (await getClientsTrainingByClientId(store.student.student_id)).data

                currentTarriffGroup.value = allTarriffsGroups.value.filter(group => {
                    return group.group_id === props.training.tariff_group_id
                })[0]

                currentClientsTariffs.value = (await getAllClientTariffsByClientId(store.student.student_id)).data
                if (!props.training.superposition) {
                    currentClientsTariffs.value = currentClientsTariffs.value.filter(tariff => {
                        return tariff.tarrif_group_id === props.training.tariff_group_id
                    })
                } else {
                    currentClientsTariffs.value = currentClientsTariffs.value
                }

                let clientBooksWithCurrentTarriffGroup = []
                console.log('currentTarriffGroup.value', currentTarriffGroup.value)
                if (currentTarriffGroup.value) {
                    currentGroupTarriffs.value = currentTarriffGroup.value.tariffs

                    clientBooksWithCurrentTarriffGroup = clientBooks.value.filter(book => {
                        return currentGroupTarriffs.value.find(tarriff => {
                            return tarriff.tariff_id === book.tariff_id
                        })
                    })
                } else {
                    currentGroupTarriffs.value = [...allTarriffsGroups.value[0].tariffs, ...allTarriffsGroups.value[1].tariffs]
                    console.log('324234', currentGroupTarriffs.value)

                    clientBooksWithCurrentTarriffGroup = clientBooks.value.filter(book => {
                        return currentGroupTarriffs.value.find(tarriff => {
                            return tarriff.tariff_id === book.tariff_id
                        })
                    })
                }

                clientBooksWithCurrentTarriffGroup = clientBooksWithCurrentTarriffGroup.filter(book => {
                    return book.status === 'planed'
                })

                clientAvalaibelBooks.value = currentClientsTariffs.value.reduce((acc, curr) => {
                    return acc + curr.training_remains
                }, 0)

                console.log('clientAvalaibelBooks.value', clientAvalaibelBooks.value)
                console.log('clientBooksWithCurrentTarriffGroup.length', clientBooksWithCurrentTarriffGroup.length)

                clientAvalaibelBooks.value = clientAvalaibelBooks.value - clientBooksWithCurrentTarriffGroup.length

                if (props.training.superposition) {
                    currentTarriff.value = store.clientTariffs[props.currentTarriffId]    
                } else {
                    currentTarriff.value = store.clientTariffs.find(tariff => tariff.tarrif_group_id === props.training.tariff_group_id);
                }

                console.log('currentTarriff.value.date_expired', new Date(currentTarriff.value.date_expired).setHours(23, 59, 59, 999))
                currentTarrifDateExpire.value = new Date(currentTarriff.value.date_expired).setHours(23, 59, 59, 999);
            });

            const addClientBook = async () => {
                let currentBookTarrif = null
                loading.value = true;
                console.log('props.currentTarriffId', props.currentTarriffId);
                if (props.training.superposition) {
                    currentBookTarrif = store.clientTariffs[props.currentTarriffId]    
                } else {
                    currentBookTarrif = store.clientTariffs.find(tariff => tariff.tarrif_group_id === props.training.tariff_group_id);
                }                

                if (!currentBookTarrif) {
                    alert('Ви не можете записатись на це тренування, так як у вас немає підходящого абонементу');
                    return;
                }
                const response = await addClientTrainingBook({
                    training_id: props.training.training_id,
                    client_id: store.student.student_id,
                    status: "planed",
                    date: props.training.date,
                    start_time: props.training.start_time,
                    end_time: props.training.end_time,

                    tariff_id: currentBookTarrif.tariff_id,
                    tariff_price: currentBookTarrif.tariff_price,
                    tariff_training_count: currentBookTarrif.tariff_training_count,

                    client_label: store.student.student_name + ' ' + store.student.student_surname,
                    client_photo: store.student.photo,
                    client_phone: store.student.phone,
                });

                router.go();
            }

            const parseType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчинг'
                    case 'pilates_preg':
                        return 'пілатес(вагітні)'
                }
            }

            return {
                apiURL,
                addClientBook,
                parseType,
                clientAvalaibelBooks,
                currentTarrifDateExpire,
                trainingDate,
                loading
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>

</style>