import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    meta: {
      component: 'Main'
    }
  },
  {
    path: '/edit-data',
    name: 'edit-student-data',
    component: MainLayout,
    meta: {
      component: 'EditData'
    }
  },
  {
    path: '/agreement-personal-data',
    name: 'agreement-personal-data',
    component: MainLayout,
    meta: {
      component: 'AgreementPersonalData'
    }
  },
  {
    path: '/school-rules',
    name: 'school-rules',
    component: MainLayout,
    meta: {
      component: 'SchoolRules'
    }
  },

  {
    path: '/payment-view',
    name: 'payment-view',
    component: MainLayout,
    meta: {
      component: 'Payment'
    }
  },

  {
    path: '/payment-redirect',
    name: 'payment-redirect',
    component: MainLayout,
    meta: {
      component: 'PaymentRedirect'
    }
  },

  {
    path: '/wallpaper-loader',
    name: 'wallpaper-loader',
    component: MainLayout,
    meta: {
      component: 'WallpaperLoader'
    }
  },

  {
    path: '/income-history',
    name: 'income-history',
    component: MainLayout,
    meta: {
      component: 'IncomesView'
    }
  },

  {
    path: '/messages',
    name: 'messages',
    component: MainLayout,
    meta: {
      component: 'MessagesView'
    }
  },

  {
    path: '/current-message',
    name: 'current-message',
    component: MainLayout,
    meta: {
      component: 'CurrentMessageView'
    }
  },

  {
    path: '/team',
    name: 'team',
    component: MainLayout,
    meta: {
      component: 'TeamView'
    }
  },

  {
    path: '/all-students-in-group',
    name: 'AllStudentsInGroup',
    component: MainLayout,
    meta: {
      component: 'AllStudentsInGroup'
    }
  },

  {
    path: '/stats',
    name: 'StatsPage',
    component: MainLayout,
    meta: {
      component: 'StatsPage'
    }
  },

  {
    path: '/photo-loader',
    name: 'photo-loader',
    component: MainLayout,
    meta: {
      component: 'PhotoLoader'
    }
  },

  {
    path: '/registration',
    name: 'registration',
    component: MainLayout,
    meta: {
      component: 'RegistrationView'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
